export const environment = {
  production: true,
  name: 'production',
  well_known_url: 'https://agrosurvey.eftas.services/.well-known/ams',
  keycloak_url: 'https://auth.agrosurvey.eftas.services',
  keycloak_realm: 'ams',
  keycloak_clientId: 'agrosurvey_amsdashboard',
  keycloak_redirectUri: 'https://agrosurvey.eftas.services',
  holiday_special: false
}
